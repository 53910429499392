import { BrandDot } from '@/components/BrandDot'
import { SectionWrapper } from '@/components/SectionWrapper'
import { useTheme } from '@/store'
import { TestimonialsHeroQuery } from '@/types/__generated__/TestimonialsHeroQuery'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query TestimonialsHeroQuery {
    contentfulPageTestimonials {
      heroTitle
      heroSubtitle
    }
  }
`

export const TestimonialsHero: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageTestimonials: { heroTitle, heroSubtitle },
  } = useStaticQuery<TestimonialsHeroQuery>(query)

  return (
    <SectionWrapper id={id}>
      <div
        css={css`
          margin-bottom: 1em;

          ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
          text-align: center;
        `}
      >
        <h1
          css={css`
            color: ${theme.color.text_default};
            font-size: 1em;
            letter-spacing: -0.03em;
          `}
        >
          {heroTitle}
          <BrandDot />
        </h1>
        <p
          css={css`
            margin-top: 0.5rem;
            color: ${theme.color.text_light};
            ${fluidFontSize(16, 24, 320, theme.vwImageCapWidth)};
            line-height: 1.25;
          `}
        >
          {heroSubtitle}
        </p>
      </div>
    </SectionWrapper>
  )
}
