import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import { useTheme } from '@/store'
import { padding } from '@/utils/mixins'
import { resolve_contentful_reference } from '@/utils/resolve_contentful_reference'
import { TestimonialsListQuery } from '@/types/__generated__/TestimonialsListQuery'

type Props = {
  id: string
}

const query = graphql`
  query TestimonialsListQuery {
    contentfulPageTestimonials {
      testimonials {
        contentful_id
        name
        projectLink {
          __typename
          ... on ContentfulProject {
            slug
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        supportingDocument {
          fluid(maxWidth: 512, quality: 95) {
            ...ContentfulFluid
          }
        }
        reference
      }
    }
  }
`

export const TestimonialsList: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageTestimonials: { testimonials },
  } = useStaticQuery<TestimonialsListQuery>(query)

  return (
    <section
      id={id}
      css={css`
        ${padding}
        padding-top: 0;
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 2rem;

            ${theme.mq.md} {
              grid-template-columns: 1fr 1fr;
            }
          `}
        >
          {testimonials.map(testimonial => {
            const {
              contentful_id,
              name,
              description,
              reference,
              supportingDocument,
              projectLink,
            } = testimonial
            const link = projectLink
              ? resolve_contentful_reference(testimonial.projectLink)
              : undefined

            return (
              <article
                key={contentful_id}
                css={css`
                  box-shadow: ${theme.elevation[2]};
                  border-radius: ${theme.radius.card};
                  border: solid 1px ${theme.color.line};
                `}
              >
                <h2
                  css={css`
                    padding: 5%;
                    font-size: ${theme.size[2]};
                    color: ${theme.color.text_light};
                  `}
                >
                  {name}
                </h2>
                <div
                  css={css`
                    padding: 0 0.5rem;
                  `}
                >
                  <Img
                    fluid={supportingDocument.fluid}
                    alt=""
                    css={css`
                      border-radius: ${theme.radius.photo};
                      overflow: hidden;
                      box-shadow: ${theme.elevation[2]};
                    `}
                  />
                </div>
                <div
                  css={css`
                    padding: 5%;
                  `}
                >
                  <div
                    css={css`
                      color: ${theme.color.text_light};
                      line-height: 1.5;

                      ol,
                      ul {
                        list-style-position: inside;
                      }

                      ul {
                        list-style-type: circle;
                      }
                    `}
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html,
                    }}
                  />

                  {reference ? (
                    <div
                      css={css`
                        margin-top: 1rem;
                        color: ${theme.color.text_light};
                      `}
                    >
                      <span
                        css={css`
                          font-style: italic;
                        `}
                      >
                        by:
                      </span>{' '}
                      <span>{testimonial.reference}</span>
                    </div>
                  ) : null}

                  {link ? (
                    <Link
                      to={link}
                      css={css`
                        display: inline-block;
                        margin-top: 2rem;
                        color: ${theme.color.text_on_accent_bg};
                        background-color: ${theme.color.accent};
                        border-radius: ${theme.radius.button};
                        text-decoration: none;
                        padding: 0.75rem 2rem 1rem;
                        transition: all 0.1s ease-out;

                        &:hover {
                          opacity: 0.75;
                        }
                      `}
                    >
                      View project
                    </Link>
                  ) : null}
                </div>
              </article>
            )
          })}
        </div>
      </div>
    </section>
  )
}
