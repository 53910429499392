import React from 'react'
import { graphql } from 'gatsby'
import { TestimonialsPageQuery } from '@/types/__generated__/TestimonialsPageQuery'
import { SEO } from '@/components/SEO'
import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { TestimonialsHero } from '@/sections/TestimonialsHero'
import { TestimonialsList } from '@/sections/TestimonialsList'

export const query = graphql`
  query TestimonialsPageQuery {
    contentfulPageTestimonials {
      title
      metaDescription
    }
  }
`

type Props = {
  data: TestimonialsPageQuery
}

const TestimonialsPage: React.FC<Props> = ({ data }) => {
  const { title, metaDescription } = data.contentfulPageTestimonials

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumb>

      <TestimonialsHero id="testimonials-hero" />

      <TestimonialsList id="testimonials-list" />
    </>
  )
}

export default TestimonialsPage
